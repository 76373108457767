<template>
  <div class="page page-account">
    <b-col class="py-3">
      <GameHeader :page-name="$t('WebShop')"/>
      <RowLine />
      <div class="game-feature">
        <h1 class="feature-title">{{$t("WebShop")}}</h1>
        <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.idServer" :options="servers" value-field="id" text-field="name" :disabled="loading || formData.idGame < 1"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.idRole" :options="roles" value-field="id" text-field="name" :disabled="loading || formData.idServer < 1"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <b-button v-for="(category, index) in categories" :key="index" :variant="category.id === formData.idCategory ? 'primary' : 'outline-primary'" class="btn-rounded mr-1 mb-1" @click="formData.idCategory = category.id">
              {{category.name}}
            </b-button>
          </b-form-group>
          <b-form-group v-if="packages.length === 0">
            <NotFoundPanel/>
          </b-form-group>
          <b-form-group v-else>
            <b-row>
              <b-col sm="6" v-for="(item, index) in packages" :key="index" class="mb-3">
                <b-card class="ws">
                  <template #header>
                    <div>
                      <h3>{{item.name}}</h3>
                      <div v-if="item.buyTimes > 0" class="ws-buy-times" v-html="$t('You have {0} purchases left', [item.buyTimes])"></div>
                    </div>
                  </template>
                  <template #footer>
                    <div class="d-flex align-items-center justify-content-between">
                      <div>
                        <div class="ws-amount"><b>{{item.amount.$toString()}}</b> {{$config.goldName}}</div>
                        <div class="ws-price" v-if="item.price > item.amount">{{item.price.$toString()}} {{$config.goldName}}</div>
                      </div>
                      <b-button class="btn-rounded" variant="primary" size="sm" @click="onSubmit(item)">{{$t("Buy")}}</b-button>
                    </div>
                  </template>
                  <perfect-scrollbar>
                    <div v-for="(goods, i) in item.items" :key="i" class="ws-goods"><img :src="goods.imageUrl.getUrl()"/>{{goods.name}} x {{$n(goods.quantity)}}</div>
                  </perfect-scrollbar>
                </b-card>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group class="pt-2">
            <div class="font-weight-bold">
              1. Web shop is a place to sell rare and precious items in the game.
            </div>
            <div>
              2. The currency of purchase and sale at the web shop is Coin, the way to receive Coin is to top up the card.
            </div>
            <div>
              3. After purchasing the item will be sent to the character mailbox in the game.
            </div>
            <div class="font-weight-bold text-danger">
              4. Note that after purchase is not refundable, please note that if purchased by mistake, we will not be responsible.
            </div>
            <div>
              5. Buying items at this Shop will not increase benefits or VIP points in the game, please pay attention to avoid miscalculation.
            </div>
          </b-form-group>
        </ValidationObserver>
        <LoadingModal :show="loading"/>
      </div>
    </b-col>
  </div>
</template>

<script>
import GameHeader from "@/components/headers/GameHeader";
import serverGameService from "@/services/serverGameService";
import webShopService from "@/services/webShopService";
import roleService from "@/services/roleService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "Index",
  components: {GameHeader},
  data() {
    return {
      loading: false,
      timeout: null,
      servers: [{
        id: 0,
        name: this.$t("Select a server")
      }],
      roles: [{
        id: 0,
        name: this.$t("Select a role")
      }],
      categories: [],
      packages: [],
      formData: {
        idCategory: 0,
        idGame: 0,
        idServer: 0,
        idRole: 0,
        idWebShop: 0
      }
    }
  },
  created() {
    this.$bridge.$on("setCurrentGame", this.onSetGame);
  },
  beforeDestroy() {
    this.$bridge.$off("setCurrentGame", this.onSetGame);
  },
  methods: {
    onSetGame(game) {
      if (!game)
      {
        this.$gotoHomePage();
        return;
      }

      this.formData.idGame = game.id;
      this.loadServers(game.id);
    },
    async loadPackages() {
      if (this.timeout != null) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.timeout = setTimeout(async () => {
        this.categories = [];
        this.packages = [];
        this.loading = true;
        const response = await webShopService.get(this.formData);
        this.loading = false;

        if (!response) {
          await dialogHelper.alert("Connect to server failed. Please check your internet connection");
          return;
        }

        if (response.error) {
          await dialogHelper.alert(response.message);
          return;
        }

        const data = response.data;
        let categories = [{id: 0, name: this.$t("All")}];
        categories = [...categories, ...data.categories];
        this.categories = categories;
        this.packages = data.packages;
      }, 300);
    },
    async loadServers(idGame) {
      let servers = [{
        id: 0,
        name: this.$t("Select a server")
      }];

      const response = await serverGameService.getAllByGame(idGame);
      this.loading = false;
      if (!response)
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
      else if (response.error)
        await dialogHelper.alert(response.message);
      else
        servers = [...servers, ...response.data];

      this.servers = servers;
      this.formData.idServer = servers.length > 1 ? servers[1].id : 0;
    },
    async onSubmit(item) {
      if (!await dialogHelper.confirm(this.$t("Are you sure to buy {0}", [item.name])))
        return;


      this.formData.idWebShop = item.id;
      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await webShopService.buy(this.formData, await this.$recaptcha('webshop'))
      this.loading = false;

      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      await dialogHelper.alert(response.message, "success");
      await this.loadPackages();
    }
  },
  watch: {
    "formData.idServer": async function (newValue) {
      let roles = [{
        id: 0,
        name: this.$t("Select a role")
      }];

      if (newValue > 0) {
        this.loading = true;
        const response = await roleService.getRolesByServer(newValue);
        this.loading = false;
        if (!response)
          await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        else if (response.error)
          await dialogHelper.alert(response.message);
        else
          roles = [...roles, ...response.data];
      }

      this.roles = roles;
      this.formData.idRole = roles.length > 1 ? roles[1].id :  0;

      await this.loadPackages(this.formData.idGame);
    },
    "formData.idRole": async function (newValue) {
      await this.loadPackages(this.formData.idGame);
    },
    "formData.idCategory": async function (newValue) {
      await this.loadPackages(this.formData.idGame);
    },
  }
}
</script>

<style scoped lang="scss">
.ws {
  .card-header {
    height: 62px;
    display: flex;
    align-items: center;

    h3 {
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .ws-buy-times {
      font-size: 12px;
      padding-top: 2px;

      b {
        color: red !important;
      }
    }
  }

  .card-body {
    padding: 0;

    .ps {
      width: 100%;
      height: 140px;
      overflow: hidden;
      padding: 15px;

      .ws-goods {
        margin-bottom: 5px;

        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
          margin-right: 5px;
        }
      }
    }
  }

  .card-footer {
    .ws-price {
      color: red;
      font-size: 12px;
      line-height: 12px;
      text-decoration: line-through;
    }
  }
}
</style>
