import httpHelper from "@/helpers/httpHelper";

const webShopService = {
    async get(data) {
        return await httpHelper.get("/WebApi/WebShop/Get", data);
    },
    async buy(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/WebShop/Buy", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
};

export default webShopService;